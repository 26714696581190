<template>
  <v-row>
    <v-col cols="12">
      <h2>{{ $t("coupon_management.head") }}</h2>
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-title> {{ $t("coupon_management.head2") }} </v-card-title>
        <v-card-actions>
          <v-spacer />
          <span class="px-3 mt-5">{{ $t("search") }}:</span>
          <div style="width: 150px" class="mt-5">
            <v-text-field v-debounce:500ms="searchKeyword" outlined dense hide-details />
          </div>
        </v-card-actions>
        <v-card-actions class="pt-0">
          <v-data-table mobile-breakpoint="0" style="width: 100%" :headers="headers" :items="coupons"
            :no-data-text="$t('not_found')" :no-results-text="$t('not_found')" :options.sync="pagination"
            :server-items-length="pagination.totalItems" :items-per-page="pagination.rowsPerPage" :footer-props="{
              'items-per-page-options': [10, 25, 30, 40, 50],
            }" :loading="loading" :search="search">
            <template v-slot:[`item.status`]="{ item }">
              <v-btn v-if="item.deletedAt" class="text-capitalize" style="background-color: transparent !important"
                disabled>
                <v-icon small color="white">x-square</v-icon>
                <span class="px-3" style="color: #ff4c51 !important">Cancel</span>
              </v-btn>
              <v-btn v-else class="text-capitalize" style="background-color: transparent !important" disabled>
                <v-icon small color="white">bi-check-square</v-icon>
                <span class="px-3" style="color: #009688 !important">Active</span>
              </v-btn>
            </template>
            <template v-slot:[`item.credit`]="{ item }">
              {{ item.credit | currency }}
            </template>
            <template v-slot:[`item.turn_over`]="{ item }">
              {{ item.turn_over | currency }}
            </template>
            <template v-slot:[`item.totalCredit`]="{ item }">
              {{ item.totalCredit | currency }}
            </template>
            <template v-slot:[`item.createdAt`]="{ item }">
              <span v-if="item.createdAt">{{ formatDateTime(item.createdAt) }}</span>
            </template>
            <template v-slot:[`item.time_use`]="{ item }">
              <span>{{ formatDateTime(item.time_use) }}</span>
            </template>
            <template v-slot:[`item.time_expire`]="{ item }">
              <span>{{ formatDateTime(item.time_expire) }}</span>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-btn v-if="!item.createdAt && !item.deletedAt" color="error" class="white--text text-capitalize"
                @click="deleteCoupon(item)">
                <v-icon small color="white">mdi-delete-outline</v-icon>
                <span class="px-3 white--text">{{ $t('coupon_management.cancel') }}</span>
              </v-btn>
              <v-btn v-else-if="(item.createdAt && !item.deletedAt) || (item.createdAt && item.deletedAt)" class="text-capitalize"
                style="background-color: transparent !important" disabled>
                <v-icon small color="white">bi-check-square</v-icon>
                <span class="px-3" style="color: #009688 !important">{{ $t('coupon_management.used') }}</span>
              </v-btn>
              <v-btn v-else class="text-capitalize" style="background-color: transparent !important"
                disabled>
                <span class="px-3" style="color: #ff4c51 !important">{{ $t('coupon_management.coupon_cancel') }}</span>
              </v-btn>
            </template>
          </v-data-table>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import DatePickerInput from '@/components/input/DatePickerInput.vue'
import SelectInput from '@/components/input/SelectInput.vue'
import store from '@/store'
import moment from 'moment'
export default {
  components: {
    DatePickerInput,
    SelectInput,
  },

  data() {
    return {
      dateFrom: moment().subtract(3, 'days').format('YY-MM-DD'),
      dateTo: moment().format('YY-MM-DD'),
      date: null,
      currentMemberDetail: {},
      loading: false,
      search: '',
      headers: [
        {
          text: this.$t('coupon_management.create_header1'),
          value: 'status',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('coupon_management.create_header2'),
          value: 'key',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('coupon_management.create_header3'),
          value: 'credit',
          width: '95px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('coupon_management.create_header4'),
          value: 'turn_over',
          width: '95px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('coupon_management.create_header7'),
          value: 'time_use',
          width: '95px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('coupon_management.create_header8'),
          value: 'time_expire',
          width: '95px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('coupon_management.create_header5'),
          value: 'phone',
          width: '95px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('coupon_management.create_header6'),
          value: 'createdAt',
          width: '95px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('coupon_management.create_header9'),
          value: 'action',
          width: '95px',
          align: 'center',
          sortable: false,
        },
      ],
      coupons: [],
      pagination: {
        page: 1,
        rowsPerPage: 25,
        totalItems: 0,
      },
    }
  },
  async created() {
    this.addLogPage()
  },
  watch: {
    pagination: {
      async handler(newValue, oldValue) {
        let cont = false
        if (oldValue.page !== newValue.page) cont = true
        if (oldValue.itemsPerPage !== newValue.itemsPerPage) cont = true
        if (oldValue.sortBy !== newValue.sortBy) cont = true
        if (oldValue.sortDesc !== newValue.sortDesc) cont = true
        if (cont) {
          this.loading = true
          const result = await this.$store.dispatch(
            'getCouponDetail',
            `page=${newValue.page}&rows=${newValue.itemsPerPage}&name=${this.$route.params.name}&createdAt=${this.$route.params.createdAt}`,
          )
          this.pagination.totalItems = result.count
          this.coupons = result.data
          this.loading = false
        }
      },
      deep: true,
    },
  },
  async mounted() {
    try {
      // const result = await this.$store.dispatch('getCouponDetail', `id=${this.$route.params.value}&page=1$rows=10`)
      // this.pagination.totalItems = result.count
      // this.coupons = result.data
    } catch (e) { }
  },
  methods: {
    async addLogPage() {
      try {
        let data
        const userSystem = store.getters.getuserInfo
        await fetch('https://jsonip.com/').then(res =>
          res.json().then(
            el =>
            (data = {
              ip: el.ip,
              name: 'คูปอง',
              url: window.location.href,
              detail: 'รายการคูปอง',
              admin: userSystem.name ? userSystem.name : '',
            }),
          ),
        )
        await this.$store.dispatch('addLogPage', data)
      } catch (e) {
        // if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
        //   this.$swal.fire({
        //     icon: 'error',
        //     title: e.message,
        //   })
        // }
      }
    },
    async deleteCoupon(item) {
      this.$swal
        .fire({
          icon: 'warning',
          title: this.$t('alert'),
          text: `${this.$t('coupon_management.delete_confirm')} key: ${item.key}`,
          showCancelButton: true,
          confirmButtonText: this.$t('confirm'),
          cancelButtonText: this.$t('cancel'),
        })
        .then(async result => {
          if (result.isConfirmed) {
            const param = `?key=${item.key}`
            const deleteCoupon = await this.$store.dispatch('deleteCoupon', param)
            this.$swal.fire(this.$t('coupon_management.delete_successful'), '', 'success')
            const result = await this.$store.dispatch(
              'getCouponDetail',
              `page=1&rows=${this.pagination.rowsPerPage}&name=${this.$route.params.name}&createdAt=${this.$route.params.createdAt}`,
            )
            this.pagination.totalItems = result.count
            this.pagination.page = 1;
            this.coupons = result.data
            this.loading = false
          }
        })
    },

    async searchKeyword(value) {
      this.loading = true
      let result = {}

      if (value) {
        result = await this.$store.dispatch(
          'getCouponDetail',
          `page=1&rows=${this.pagination.rowsPerPage}&name=${this.$route.params.name}&createdAt=${this.$route.params.createdAt}&search=${value}`,
        )
      } else {
        result = await this.$store.dispatch(
          'getCouponDetail',
          `page=1&rows=${this.pagination.rowsPerPage}&name=${this.$route.params.name}&createdAt=${this.$route.params.createdAt}`,
        )
      }
      this.pagination.totalItems = result.count
      this.pagination.page = 1;
      this.coupons = result.data
      this.loading = false
    },
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD')
    },
    formatDateTime(date) {
      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
  },
}
</script>
